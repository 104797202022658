// eslint-disable-next-line no-restricted-imports
import {Avatar, type AvatarProps} from '@primer/react'
import type {Model} from '@github-ui/marketplace-common'
import {testIdProps} from '@github-ui/test-id-props'

export type PublisherAvatarProps = Omit<AvatarProps, 'src'> & {
  // We render this avatar in a few places, one of which has a `MarketplaceItem` as the `Model` object.
  logoUrl: Model['logo_url']
  darkModeIcon: Model['dark_mode_icon']
  publisher: Model['publisher']
  square?: boolean
}

export function PublisherAvatar({logoUrl, darkModeIcon, publisher, square = true, ...rest}: PublisherAvatarProps) {
  let iconSrc = logoUrl
  if (!iconSrc || publisher === 'AI21 Labs') {
    iconSrc = darkModeIcon ? `data:image/svg+xml;base64,${darkModeIcon}` : ''
  }

  return (
    <Avatar square={square} alt={`${publisher} logo`} src={iconSrc} {...testIdProps('publisher-avatar')} {...rest} />
  )
}

try{ PublisherAvatar.displayName ||= 'PublisherAvatar' } catch {}
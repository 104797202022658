import {createContext, useContext, useEffect, useMemo, useState, useCallback, useRef} from 'react'
import {useSearchParams} from '@github-ui/use-navigate'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import type {SearchResults} from '../types'
import {allPublishersOption, allTasksOptionID} from '../utilities/model-filter-options'
import {useSearchResults} from './SearchResultsContext'
import {useSort} from './SortContext'
import {useFeaturedListings} from './FeaturedListingsContext'
import {useRecommendedListings} from './RecommendedListingsContext'
import {useRecentlyAddedListings} from './RecentlyAddedListingsContext'
import {useCreators} from './CreatorsContext'
import {useCategory} from './CategoryContext'
import {useSearchType} from './SearchTypeContext'
import {useCopilotApp} from './CopilotAppContext'
import {useModelsTask} from './ModelsTaskContext'
import {useModelsPublisher} from './ModelsPublisherContext'
import {useOnParsedQueryChange} from '../hooks/use-on-parsed-query-change'
import {usePage} from './PageContext'
import {useQuery} from './QueryContext'
import {updateUrl} from '@github-ui/history'

export interface FilterContextType {
  loading: boolean
  legacyOnQueryChange: (query: string) => void
  onQueryChange: (query: string, newType: string | null) => void
  filter: string
  setFilter: (filter: string) => void
  isSearching: boolean
}
const filterOptions = {
  all: 'All',
  free_trial: 'Free trial',
}
export const FilterContext = createContext<FilterContextType>({
  loading: false,
  legacyOnQueryChange: () => undefined,
  onQueryChange: () => undefined,
  filter: filterOptions.all,
  setFilter: () => undefined,
  isSearching: false,
})
export function useFilterContext() {
  return useContext(FilterContext)
}
export function FilterProvider({children}: {children: React.ReactNode}) {
  const firstUpdate = useRef(true)
  const {setFeatured} = useFeaturedListings()
  const {setRecommended} = useRecommendedListings()
  const {setRecentlyAdded} = useRecentlyAddedListings()
  const {searchResults, setSearchResults} = useSearchResults()
  const {resetSort, sort, isDefaultSort} = useSort()
  const {creators, setCreators} = useCreators()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const {query, setQuery} = useQuery()

  const {page, setPage} = usePage()

  const defaultFilter = useMemo(() => {
    if (searchParams.has('filter')) {
      const filter = searchParams.get('filter')
      if (filter === 'free_trial') {
        return filterOptions.free_trial
      }
    }
    return filterOptions.all
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [filter, setFilter] = useState(defaultFilter)

  const {type, setType} = useSearchType()
  const {category, setCategory} = useCategory()
  const {task, setTask} = useModelsTask()
  const {publisher, setPublisher} = useModelsPublisher()
  const {copilotApp, setCopilotApp} = useCopilotApp()

  const onModelsQueryChange = useCallback(() => {
    setTask(allTasksOptionID)
    setPublisher(allPublishersOption)
  }, [setPublisher, setTask])

  const legacyOnQueryChange = useCallback(
    (qry: string) => {
      // When setting a new query, we reset all filters and sort
      setPage(1)
      setFilter(filterOptions.all)
      setCreators('All creators')
      if (type === 'models') {
        onModelsQueryChange()
      } else {
        setType(null)
      }
      resetSort()
      setCategory(null)
      setCopilotApp(null)
      setQuery(qry.trim())
    },
    [onModelsQueryChange, resetSort, setCategory, setCopilotApp, setCreators, setPage, setQuery, setType, type],
  )

  const onQueryChange = useCallback(
    (qry: string, newType: string | null) => {
      // When setting a new query, we reset all filters and sort
      setPage(1)
      setFilter(filterOptions.all)
      setCreators('All creators')
      if (newType === 'models') onModelsQueryChange()
      resetSort()
      setQuery(qry.trim())
    },
    [onModelsQueryChange, resetSort, setCreators, setPage, setQuery],
  )

  const shouldDisplaySearchResults = useMemo(() => {
    return !!(query || category || type || copilotApp)
  }, [category, copilotApp, query, type])
  const [isSearching, setIsSearching] = useState(shouldDisplaySearchResults)

  const onParsedQueryChange = useOnParsedQueryChange()
  const parsedQueryStr = searchResults.parsedQuery ? JSON.stringify(searchResults.parsedQuery) : ''
  const fetchSearchResults = useCallback(
    async (path: string) => {
      setLoading(true)
      const response = await verifiedFetchJSON(path)
      const data = await response.json()
      let newParsedQuery: SearchResults['parsedQuery']
      if (shouldDisplaySearchResults) {
        newParsedQuery = data.parsedQuery
        // This will be just search results
        setSearchResults(data)
      } else {
        // This will be the full index payload
        setFeatured(data.featured)
        setRecommended(data.recommended)
        setRecentlyAdded(data.recentlyAdded)
        const newSearchResults = data.searchResults ?? data.results
        newParsedQuery = newSearchResults?.parsedQuery
        setSearchResults(newSearchResults)
      }
      if (newParsedQuery && parsedQueryStr !== JSON.stringify(newParsedQuery)) onParsedQueryChange(newParsedQuery)
      setIsSearching(shouldDisplaySearchResults)
      setLoading(false)
    },
    [
      parsedQueryStr,
      onParsedQueryChange,
      setFeatured,
      setRecentlyAdded,
      setRecommended,
      setSearchResults,
      shouldDisplaySearchResults,
    ],
  )

  useEffect(() => {
    if (firstUpdate.current) {
      // The app is initially rendered with a payload so we don't want to run this on the first page load
      firstUpdate.current = false
      return
    }

    const newParams = new URLSearchParams()
    if (query || !isDefaultSort) {
      if (isDefaultSort) {
        newParams.set('query', query)
      } else {
        newParams.set('query', `${query} sort:${sort}`.trim())
      }
    }
    if (filter === filterOptions.free_trial) newParams.set('filter', 'free_trial')
    if (creators === 'Verified creators') newParams.set('verification', 'verified_creator')
    if (page !== 1) newParams.set('page', page.toString())
    if (category) newParams.set('category', category)
    if (publisher && publisher !== allPublishersOption) newParams.set('publisher', publisher)
    if (task && task !== allTasksOptionID) newParams.set('task', task)
    if (type) newParams.set('type', type)
    if (copilotApp) newParams.set('copilot_app', copilotApp)

    const path = newParams.toString() ? `/marketplace?${newParams.toString()}` : '/marketplace'
    updateUrl(path)
    fetchSearchResults(path)
  }, [
    fetchSearchResults,
    isDefaultSort,
    query,
    filter,
    creators,
    sort,
    page,
    category,
    type,
    publisher,
    task,
    copilotApp,
  ])

  const value = useMemo<FilterContextType>(() => {
    return {
      loading,
      legacyOnQueryChange,
      onQueryChange,
      filter,
      setFilter,
      task,
      setTask,
      publisher,
      setPublisher,
      type,
      setType,
      isSearching,
    }
  }, [
    loading,
    legacyOnQueryChange,
    onQueryChange,
    filter,
    setFilter,
    task,
    setTask,
    publisher,
    setPublisher,
    type,
    setType,
    isSearching,
  ])

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

try{ FilterContext.displayName ||= 'FilterContext' } catch {}
try{ FilterProvider.displayName ||= 'FilterProvider' } catch {}